.pagination {
  .btn--icon {
    width: 38px;
    height: 38px;

    @screen s {
      width: 56px;
      height: 56px;
    }
  }
}
