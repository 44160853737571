/* stylelint-disable selector-class-pattern */

.wpml-ls-statics-shortcode_actions.wpml-ls.wpml-ls-legacy-dropdown-click.js-wpml-ls-legacy-dropdown-click {
  width: auto;

  .js-wpml-ls-sub-menu,
  a {
    border: none;
  }
}
