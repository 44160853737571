::placeholder {
  color: black !important;
  opacity: 0.4;
}

.form__container > * {
  display: inline-block;

  + * {
    @apply mt-3;

    @screen s {
      @apply mt-0;
    }
  }
}

.form__container--complex {
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 100%;

    @each $breakpoint in (s m l xl xxl) {
      @screen #{$breakpoint} {
        width: 50%;
        padding: 0 calc(theme('gridPlugin.gutterWidth.#{$breakpoint}', 1) * 0.5 * 1rem);
      }
    }

    &:nth-child(odd) {
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-right: 0;
    }

    &:nth-child(n + 3) {
      @screen s {
        @apply mt-5;
      }
    }
  }
}

.form__group--is-hidden {
  display: none;
}

.form__group--radio {
  // stylelint-disable
  .gfield_label,
  .ginput_container {
    display: inline-block;
  }
  // stylelint-enable

  > .form__container {
    @apply ml-3;

    @screen s {
      @apply ml-6;
    }
  }
}

.form__group--fileupload {
  @apply relative;

  [type='file'] {
    cursor: pointer;
    @apply opacity-0;
    @apply absolute inset-0 w-full;
  }
}

.form__group + .form__group {
  @apply mt-3;

  @screen s {
    @apply mt-5;
  }
}

.form__group--html + .form__group {
  @apply mt-7;

  @screen s {
    @apply mt-5;
  }
}

.form__field {
  @apply border border-grey-form;
  width: 100%;
  padding: 0.375rem 1.5rem 0.625rem;

  &:focus {
    border-color: theme('colors.grey.cold');
  }
}

.form__field--textarea {
  max-width: 100%;
  padding: 1.375rem 1.214rem;
}

.form__field--radio {
  @apply border-0;
  padding: 0;

  + .form__field--radio {
    @apply ml-4;

    @screen s {
      @apply ml-7;
    }
  }
}

.form__field-wrapper--select {
  @apply relative;

  > .icon {
    @apply absolute left-auto;
    @apply w-6;
    @apply flex justify-center items-center;
    @apply bg-yellow;
    @apply pointer-events-none;
    top: 1px;
    right: 1px;
    bottom: 1px;

    @screen s {
      @apply w-7;
    }
  }
}

.form__field--select {
  @apply pr-6;

  @screen s {
    @apply pr-7;
  }
}

* + .form__label {
  margin-left: 0.188rem;

  @screen s {
    @apply ml-1;
  }
}

.form__footer {
  @apply flex justify-center;
  @apply mt-5;

  [type='submit'] {
    cursor: pointer;
  }

  @screen s {
    @apply justify-end;
    @apply mt-6;
  }
}

/**
 * Gravity forms
 */

// stylelint-disable
.hidden_label > label {
  display: none;
}

.hidden_sub_label {
  display: none;
}

.validation_error {
  color: crimson;

  + .gform_body {
    .gfield_description {
      display: block;
      padding-top: 6px;
      color: crimson;
      font-size: 14px;
    }
  }
}

// confirmation succes
.gform_confirmation_wrapper {
  @apply bg-blue text-white pl-10 pr-2 py-2 relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1.5em;
    width: 39px;
    height: 45px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='39px' height='45px' viewBox='0 0 39 45' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 61.2 (101010) - https://sketch.com --%3E%3Ctitle%3E59707C0D-0529-4A83-88E2-D73B0FF44052%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='DA---desktop' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Contact-envoyé' transform='translate(-145.000000, -1423.000000)'%3E%3Cg id='alert-message-envoyé' transform='translate(112.000000, 1400.000000)'%3E%3Cg id='icon-enveloppe' transform='translate(33.000000, 23.000000)'%3E%3Cpolygon id='Fill-1' fill='%23223240' opacity='0.300000012' points='0 45.0004 35 45.0004 35 19.0004 0 19.0004'%3E%3C/polygon%3E%3Cpolygon id='Fill-3' fill='%23FDDC33' points='4 40.9994 39 40.9994 39 14.9994 4 14.9994'%3E%3C/polygon%3E%3Cpolygon id='Fill-4' fill='%23FFFFFF' points='3.9996 14.9998 21.5006 29.9998 38.9996 14.9998'%3E%3C/polygon%3E%3Cpolygon id='Fill-5' fill='%23FEEA85' points='21.4991 0.0004 4.0001 14.9994 39.0001 14.9994 21.5011 0.0004'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    transform: translateY(-50%);
  }

  strong {
    font-weight: 700;
  }

  @screen s {
    @apply pr-4 py-4;
  }
}
// stylelint-enable
