.push-documents__document-link:hover {
  h3 {
    text-decoration: underline;
  }
}

.push-documents__document .icon--pdf svg {
  width: auto;
  height: 42px;
}
