/*==========================================================================*\
  Styles
\*==========================================================================*/

/* Config
\*==========================================================================*/

@import 'config';

/* Tools
\*==========================================================================*/

/* Globals
\*==========================================================================*/

@import 'tailwindcss/base';
@import 'globals/base';
@import 'globals/layout';

/* Vendors
\*==========================================================================*/

/* Elements
\*==========================================================================*/

@import 'elements/icons';
@import 'elements/buttons';

/* Components
\*==========================================================================*/

@import 'tailwindcss/components';
@import 'components/form';
@import 'components/header';
@import 'components/footer';
@import 'components/card';
@import 'components/push';
@import 'components/wysiwyg';
@import 'components/slider';
@import 'components/pagination';
@import 'components/push-documents';

/* Utilities
\*==========================================================================*/

@import 'tailwindcss/utilities';
@import 'globals/utils';

/* Plugins
\*==========================================================================*/

@import 'plugins/wpml';
