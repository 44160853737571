/*==========================================================================*\
  Mixins
\*==========================================================================*/

/* Button without appearance
\*==========================================================================*/

@mixin button-without-appearance {
  padding: 0;
  font-family: inherit;
  line-height: 0;
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: transparent;
  }
}

/* Link rollover
\*==========================================================================*/
@mixin link-hover {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 100%;
    height: 3px;
    @apply bg-yellow;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.3s transform $out-quad;
  }

  &:hover {
    &:before {
      content: '';
      transform: scaleX(1);
      transition: 0.3s transform $out-quad;
    }
  }
}
