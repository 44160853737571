/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
.header__menu--primary {
  .menu__parent--level-1 {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      border-color: transparent;
      border-width: theme('spacing.1');
      border-bottom-color: theme('colors.blue.default');
      transform: translate3d(-50%, -100%, 0);
    }

    // Increase hover mouse area with a transparent element
    &:after {
      content: '';
      z-index: 0;
      position: absolute;
      top: -1rem;
      left: 50%;
      width: 70%;
      height: 1rem;
      background-color: transparent;
      transform: translateX(-50%);
    }

    @screen s {
      left: 50%;
      display: block;
      margin-top: calc(#{theme('spacing.1')} * 1.5);
      pointer-events: none;
      opacity: 0;
      transform: translate3d(-50%, 0, 0);
      transition: opacity 0.3s ease;
    }
  }

  .menu__item--level-0 {
    > :first-child {
      position: relative;
      display: block;

      .icon {
        position: absolute;
        top: calc(100% + #{theme('spacing.1')});
        left: 50%;
        display: none;
        color: theme('colors.blue.default');
        opacity: 0;
        transform: translate3d(-50%, -50%, 0);
        transition: opacity 0.3s ease;
      }
    }

    &:hover,
    &:focus,
    &.is-active {
      > :first-child .icon {
        opacity: 1;
      }
    }
  }

  .menu__item--level-0.menu__item--has-url {
    > :first-child .icon {
      display: block;
    }
  }

  .menu__item--level-0.menu__item--has-children {
    position: relative;

    &:hover,
    &:focus {
      > :first-child .icon {
        opacity: 0;
      }

      > .menu__parent {
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  .menu__item--level-1 {
    > :first-child {
      display: flex;
      align-items: center;

      > * {
        flex-shrink: 0;
        white-space: nowrap;
      }

      .icon {
        width: theme('spacing.7');
      }

      .icon--product-type-construction svg {
        width: 66%;
      }
    }
  }

  .menu__item--level-1.menu__item--has-icon {
    margin-left: calc(#{theme('spacing.5')} / 2 * -1);
  }

  .menu__item--level-1 + .menu__item--level-1 {
    margin-top: theme('spacing.3');
  }
}

.header__menu--secondary {
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s ease;

  .menu__item:not(.card) a {
    @include link-hover;
  }

  @screen s {
    pointer-events: none;
    opacity: 0;
    transform: translate3d(0, 0, 0) !important;
    transition: opacity 0.3s ease;
  }

  .is-menu-opened & {
    transform: translate3d($menu-secondary-offset-mobile, 0, 0);

    @screen s {
      pointer-events: all;
      opacity: 1;
    }
  }

  .btn-contact {
    display: none;
  }

  .menu__parent {
    display: none;
  }

  .menu__parent--level-0 {
    display: block;

    @screen s {
      margin-top: 2rem;
      columns: 2;
    }
  }

  .menu__item--level-0 {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:nth-child(3n + 3) {
      @screen s {
        margin-bottom: 2rem;
        padding-bottom: 0;
      }
    }

    &:nth-child(3n + 1) {
      @screen s {
        padding-top: 0;
      }
    }
  }
}
