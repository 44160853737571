/*==========================================================================*\
  Base
\*==========================================================================*/

/* Base : Document styles
\*==========================================================================*/

html {
  @apply font-din;
  font-size: 16px;
}

::selection {
  @apply text-white bg-blue;
}

body {
  @apply type-base antialiased text-blue-dark;
  overflow-x: hidden;
  transition: transform 0.3s ease 20ms;

  @screen s {
    transform: none;
    transition: none;
  }

  /*
   * Data breakpoints taking space at the bottom of the page
   * Remove the blank space
   */
  &::after {
    display: none;
  }
}

.is-menu-opened {
  @apply overflow-hidden;
  transform: translate3d(-$menu-secondary-offset-mobile, 0, 0);

  @screen s {
    @apply overflow-auto;
    transform: none !important;
  }
}

// Vue onload apparence
[v-cloak] {
  opacity: 0;
}
