/*==========================================================================*\
  WYSIWYG
\*==========================================================================*/
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable no-descending-specificity */
.mce-content-body {
  @apply font-din type-base antialiased text-blue-dark;
}

.wysiwyg,
.mce-content-body {
  &.cms {
    &:last-child,
    &:only-child {
      @apply mb-10;

      @screen s {
        @apply mb-16;
      }
    }

    ul,
    ol,
    blockquote,
    p:not(.wysiwyg-primary):not(.wysiwyg-secondary):not(.wysiwyg-tertiary):not(
        .wysiwyg-epigraph
      ):not(.wysiwyg-intro) {
      @screen s {
        @apply pr-12;
      }
    }
  }

  ul + *,
  ol + *,
  blockquote + *,
  figure + *,
  iframe + *,
  p + * {
    @apply mt-8;

    @screen s {
      @apply mt-12;
    }
  }

  p + p {
    @apply mt-3;
  }

  .wysiwyg-primary,
  .wysiwyg-secondary,
  .wysiwyg-tertiary,
  .wysiwyg-epigraph,
  .wysiwyg-intro {
    @apply pr-0;
  }

  figure + *,
  .wysiwyg-intro + *,
  .wysiwyg-epigraph + *,
  .wp-caption + *,
  .mceTemp + * {
    @apply mt-8;
  }

  .wysiwyg-primary + *,
  .wysiwyg-secondary + *,
  .wysiwyg-tertiary + * {
    @apply mt-5;
  }

  ul,
  ol {
    @apply pl-2;

    li {
      @apply pl-1;
    }

    li + li {
      @apply mt-2;
    }
  }

  // stylelint-disable-next-line no-duplicate-selectors
  &.cms {
    ul,
    ol {
      @screen s {
        @apply pl-6;
      }
    }
  }

  ul {
    list-style-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy40NjQgMi45OTlWN0w0IDkgLjUzNiA3VjNMNCAxIDcuNDY0IDN6IiBzdHJva2U9IiM2QkEwQ0UiLz48L3N2Zz4=');
  }

  strong {
    @apply font-medium;
  }

  em {
    font-style: italic;
  }

  figure,
  .wp-caption,
  figcaption,
  .wp-caption-text,
  img,
  video {
    display: block;
    max-width: 100%;
    height: auto;

    &.aligncenter {
      margin: 0 auto;
    }

    &.alignright {
      margin-left: auto;
    }
  }

  figcaption {
    font-weight: 600;
  }

  figcaption,
  .wp-caption-text,
  .wp-caption-dd {
    @apply mb-2 type-subtitle text-blue;
  }

  figure,
  .wp-caption {
    @apply flex flex-col-reverse;
  }

  figure img,
  .wp-caption img {
    @apply block;
  }

  a:not(.btn):not(.accordion__btn) {
    @apply text-blue underline;

    &:visited {
      @apply text-blue-light;
    }
  }

  > :last-child:not(.btn) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

/*------------------------------------*
Two columns wysiwyg
@see archive-product.twig
*------------------------------------*/
.wysiwyg--two-col {
  @screen m {
    position: relative;
    column-count: 2;
    column-gap: 8rem;

    &:before {
      @apply text-grey-light;
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      border: 1px solid currentColor;
      transform: translateX(-50%);
    }

    ul + .btn {
      @apply ml-4;
    }
  }
}
