/*==========================================================================*\
  Utils
\*==========================================================================*/

/* Inside border
\*==========================================================================*/
.u-inside-border-b-2 {
  position: relative;

  &::before {
    @apply z-default;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
  }
}

.u-inside-border-grey-light {
  &::before {
    @apply bg-grey-light;
  }
}

/* hide scrollbar
\*==========================================================================*/
.u-hide-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-transparent;
  }
}

/* Infinite Background
\*==========================================================================*/
@responsive {
  .bg-gutter,
  .bg-infinite,
  .bg-infinite-container,
  .bg-gutter-left,
  .bg-infinite-container-left,
  .bg-gutter-right,
  .bg-infinite-container-right {
    @apply relative z-default;
  }

  .bg-gutter,
  .bg-infinite,
  .bg-infinite-container,
  .bg-gutter-left,
  .bg-infinite-container-left {
    &::before {
      @apply absolute top-0 block h-full transform z-under left-0 -translate-x-full;
      content: '';
      background-color: inherit;
    }
  }

  .bg-gutter,
  .bg-infinite,
  .bg-infinite-container,
  .bg-gutter-right,
  .bg-infinite-container-right {
    &::after {
      @apply absolute top-0 block h-full transform z-under right-0 translate-x-full;
      content: '';
      background-color: inherit;
    }
  }

  .bg-infinite-container,
  .bg-infinite-container-left {
    &::before {
      width: calc((1440px - 480px) / 2 + (#{theme('gridPlugin.gutterWidth.default')} * 0.15rem));

      @screen xs {
        width: calc((1440px - 480px) / 2 + (#{theme('gridPlugin.gutterWidth.xs')} * 0.25rem));
      }
      @screen s {
        width: calc((1440px - 768px) / 2 + (#{theme('gridPlugin.gutterWidth.s')} * 0.35rem));
      }
      @screen m {
        width: calc((1440px - 78rem) / 2 + (#{theme('gridPlugin.gutterWidth.m')} * 0.45rem));
      }
      @screen l {
        width: calc((1440px - 78rem) / 2 + (#{theme('gridPlugin.gutterWidth.l')} * 0.55rem));
      }
      @screen xl {
        width: calc((1440px - 78rem) / 2 + (#{theme('gridPlugin.gutterWidth.xl')} * 0.65rem));
      }
      @screen xxl {
        width: calc((1440px - 78rem) / 2 + (#{theme('gridPlugin.gutterWidth.xxl')} * 0.75rem));
      }
    }
  }

  .bg-gutter,
  .bg-gutter-left {
    &::before {
      width: theme('gridPlugin.gutterWidth.default');

      @screen xs {
        width: theme('gridPlugin.gutterWidth.xs');
      }
      @screen s {
        width: theme('gridPlugin.gutterWidth.s');
      }
      @screen m {
        width: theme('gridPlugin.gutterWidth.m');
      }
      @screen l {
        width: theme('gridPlugin.gutterWidth.l');
      }
      @screen xl {
        width: theme('gridPlugin.gutterWidth.xl');
      }
      @screen xxl {
        width: theme('gridPlugin.gutterWidth.xxl');
      }
    }
  }

  .bg-infinite-container,
  .bg-infinite-container-right {
    &::after {
      width: calc((1440px - 480px) / 2 + (#{theme('gridPlugin.gutterWidth.default')} * 0.5rem));

      @screen xs {
        width: calc((1440px - 480px) / 2 + (#{theme('gridPlugin.gutterWidth.xs')} * 0.5rem));
      }
      @screen s {
        width: calc((1440px - 768px) / 2 + (#{theme('gridPlugin.gutterWidth.s')} * 0.5rem));
      }
      @screen m {
        width: calc((1440px - 78rem) / 2 + (#{theme('gridPlugin.gutterWidth.m')} * 0.5rem));
      }
      @screen l {
        width: calc((1440px - 78rem) / 2 + (#{theme('gridPlugin.gutterWidth.l')} * 0.5rem));
      }
      @screen xl {
        width: calc((1440px - 78rem) / 2 + (#{theme('gridPlugin.gutterWidth.xl')} * 0.5rem));
      }
      @screen xxl {
        width: calc((1440px - 78rem) / 2 + (#{theme('gridPlugin.gutterWidth.xxl')} * 0.5rem));
      }
    }
  }

  .bg-gutter,
  .bg-gutter-right {
    &::after {
      width: #{theme('gridPlugin.gutterWidth.default')}rem;

      @screen xs {
        width: #{theme('gridPlugin.gutterWidth.xs')}rem;
      }
      @screen s {
        width: #{theme('gridPlugin.gutterWidth.s')}rem;
      }
      @screen m {
        width: #{theme('gridPlugin.gutterWidth.m')}rem;
      }
      @screen l {
        width: #{theme('gridPlugin.gutterWidth.l')}rem;
      }
      @screen xl {
        width: #{theme('gridPlugin.gutterWidth.xl')}rem;
      }
      @screen xxl {
        width: #{theme('gridPlugin.gutterWidth.xxl')}rem;
      }
    }
  }

  .bg-infinite {
    &::before,
    &::after {
      @apply w-screen;
    }
  }
}

/* Width full + gutter
\*==========================================================================*/
@responsive {
  .w-full-gutter {
    width: calc(100% + (#{theme('gridPlugin.gutterWidth.default')} * 1rem));

    @screen xs {
      width: calc(100% + (#{theme('gridPlugin.gutterWidth.xs')} * 1rem));
    }
    @screen s {
      width: calc(100% + (#{theme('gridPlugin.gutterWidth.s')} * 1rem));
    }
    @screen m {
      width: calc(100% + (#{theme('gridPlugin.gutterWidth.m')} * 1rem));
    }
    @screen l {
      width: calc(100% + (#{theme('gridPlugin.gutterWidth.l')} * 1rem));
    }
    @screen xl {
      width: calc(100% + (#{theme('gridPlugin.gutterWidth.xl')} * 1rem));
    }
    @screen xxl {
      width: calc(100% + (#{theme('gridPlugin.gutterWidth.xxl')} * 1rem));
    }
  }
}

.w-container-end {
  @screen m {
    width: calc(100% + (theme('gridPlugin.gutterWidth.m') * 1rem));
  }
  @screen l {
    width: calc((1440px - 78rem) / 2 + 100%);
  }
  @screen xl {
    width: calc((1440px - 78rem) / 2 + 100% + (theme('gridPlugin.gutterWidth.m') * 0.75rem));
  }
  @screen xxl {
    width: calc((1440px - 78rem) / 2 + 100% + (theme('gridPlugin.gutterWidth.m') * 1rem));
  }
}

.object-cover {
  object-fit: cover;
}
