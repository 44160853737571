.js-slider {
  outline: unset;
}

.flickity-viewport {
  width: 100%;
}

.front-page__hero {
  .flickity-viewport {
    position: relative;
  }

  .flickity-slider {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.slider--full {
  position: relative;

  > *:not(:first-child) {
    z-index: theme('zIndex.default') + 1;
  }

  .slider__images {
    left: 50%;
    width: 110vw;
    transform: translate3d(-50%, 0, 0);

    &::before {
      content: '';
      z-index: theme('zIndex.default');
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(
        theme('colors.blue.transparent'),
        theme('colors.blue.dark')
      );
    }

    .flickity-slider {
      height: 100%;
    }

    @screen s {
      left: 0;
      width: auto;
      transform: translate3d(0, 0, 0);
    }
  }

  .slider__image {
    top: 50%;
    width: 100%;
    min-height: 100%;
    transform: translate3d(0, -50%, 0);
  }

  .slider__item-title-wrapper {
    @screen s {
      position: relative;
    }
  }

  .slider__item-title {
    @screen s {
      display: inline-block;
    }
  }

  .slider__item {
    @screen s {
      opacity: 0.4;
      transform: translateX(30%);
      transition: 0.6s transform $out-circ, 0.3s opacity $out-expo;

      &.is-selected {
        opacity: 1;
        transform: translateX(0);

        + .slider__item {
          opacity: 0.4;
          transform: translateX(-30%);
        }
      }
    }

    @screen l {
      transform: translateX(40%);

      &.is-selected {
        transform: translateX(0);

        + .slider__item {
          transform: translateX(-40%);
        }
      }
    }
  }
}

.js-slider-item__homepage {
  width: max-content;
  visibility: hidden;
}

.js-slider-item__homepage.is-selected {
  visibility: visible;
}

/*------------------------------------*
  Progress bar
*------------------------------------*/
.slider__progress {
  position: relative;
  width: 100%;
  height: 1px;
  overflow: hidden;
  background-color: theme('colors.white.transparent');

  > span {
    display: block;
    height: 100%;
    background-color: theme('colors.yellow.default');
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }

  &.slider__progress--light {
    background-color: theme('colors.grey.light');

    > span {
      background-color: theme('colors.blue.default');
    }
  }
}
