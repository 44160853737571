/*==========================================================================*\
  Icons
\*==========================================================================*/

.icon {
  display: inline-block;
}

.icon > svg {
  display: block;
}

/* Rounded
\*==========================================================================*/
.icon--rounded {
  @apply rounded-full w-4 h-4;
  position: relative;

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* Arrow
\*==========================================================================*/
.icon--arrow-top {
  > svg {
    transform: rotate(-90deg);
  }
}

.icon--arrow-bottom {
  > svg {
    transform: rotate(90deg);
  }
}

.icon--arrow-left {
  > svg {
    transform: rotate(180deg);
  }
}

/* Caret
\*==========================================================================*/
.icon--caret-top {
  > svg {
    transform: rotate(180deg);
  }
}

.icon--caret-left {
  > svg {
    transform: rotate(90deg);
  }
}

.icon--caret-right {
  > svg {
    transform: rotate(-90deg);
  }
}

/* Building types listing
\*==========================================================================*/

.icon--building-types-listing {
  @apply text-white;

  svg {
    width: 1.5rem;
    height: 1.5rem;

    @screen s {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}

/* Sizes
\*==========================================================================*/
.icon--w-full > svg {
  width: 100%;
  height: 100%;
}

.icon--cross {
  &.icon--2x > svg {
    width: 19px;
    height: 19px;
  }
}

/* Download Card
\*==========================================================================*/
.icon--download-card {
  .icon__arrow {
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease;
  }

  a:hover & {
    .icon__arrow {
      animation-name: downloadIconArrow;
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-fill-mode: backwards;
    }
  }
}

@keyframes downloadIconArrow {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 10%, 0);
  }
}
