.push--default {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    background: no-repeat bottom right / contain;
    background-color: theme('colors.blue.default');
    background-image: url('#{$img-dir}/push-default.png');
    background-size: auto 50%;
    transform: translate3d(-50%, 0, 0);

    @screen s {
      left: 0;
      width: 100%;
      background-size: auto 100%;
      transform: translate3d(0, 0, 0);
    }
  }
}

.push--large {
  background: no-repeat bottom right -246px / contain;
  background-image: url('#{$img-dir}/push-large.png');
  background-size: auto 60%;

  &::after {
    content: '';
    background: theme('colors.blue.default');
  }

  @screen s {
    background-position: bottom right -283px;
    background-size: auto 100%;
  }

  @screen l {
    background-position: bottom right;
  }
}
