/*------------------------------------*
  Layout
*------------------------------------*/
html {
  @apply bg-white-light;
}

body {
  @apply bg-white;
  max-width: 1440px;
  margin: auto;
}

.container {
  max-width: 480px;
  margin: 0 auto;

  @screen s {
    max-width: 768px;
  }

  @screen m {
    max-width: 78rem;
  }
}

.layout--simple .layout__top {
  background-color: linear-gradient(0deg, #f5f5f5, theme('colors.white.default'));
}

.single-product .header {
  @apply bg-white-light;
}
