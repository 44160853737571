.card__btn-icon {
  right: -1px;
  bottom: -1px;
}

/*------------------------------------*
  Card showcase
*------------------------------------*/
.card--showcase {
  .card__inner,
  .btn {
    z-index: 2;
  }

  &::before {
    content: '';
    z-index: theme('zIndex.default');
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(theme('colors.blue.transparent'), theme('colors.blue.dark'));
  }
}

/*------------------------------------*
  Product card
*------------------------------------*/
.card--hover {
  .icon--plus {
    transform-origin: top center;
    transition: 0.6s transform $out-quad;
  }

  &:hover {
    .btn {
      @apply bg-yellow-light;
    }

    .icon--plus {
      transform: rotate(90deg) translateZ(0);
      transition: 0.6s transform $out-quad;
    }
  }
}

.card__link {
  width: 100%;
}
