/*==========================================================================*\
  Buttons
\*==========================================================================*/

.btn {
  @apply relative inline-block type-breadcrumb leading-normal font-bold uppercase px-5 py-2 bg-yellow border border-yellow;
  vertical-align: middle;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    @apply text-blue-dark bg-yellow-light border-yellow-light;
  }
}

.btn--white {
  @apply bg-white border border-white text-blue-dark;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    @apply bg-yellow border-yellow text-blue-dark;
  }
}

.btn--blue-dark {
  @apply bg-blue-dark border border-white text-white;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    @apply bg-yellow border-yellow text-blue-dark;
  }
}

.btn--outline-blue {
  @apply bg-transparent border border-blue-light text-blue;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    @apply bg-blue text-white border-blue;
  }
}

.btn--outline-white {
  @apply bg-transparent border border-white-transparent text-white;
  transition: background-color 0.2s ease;

  &:hover,
  &:focus {
    @apply bg-white-transparent border-white text-white;
  }
}

.btn--icon {
  @apply p-0 w-7 h-7;

  > .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn--no-appearance {
  @apply bg-transparent #{!important};
  @apply p-0 #{!important};
  @apply w-auto #{!important};
  @apply border-0 #{!important};
}

/*------------------------------------*
  Contact button in menus
  Classes can only be added to <li> and not <a> tags
*------------------------------------*/
.btn-contact {
  a {
    // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
    @extend .btn;
  }

  .icon {
    display: none !important;
  }
}
